<template>
  <v-card id="experiences" class="mt-7 pb-7" flat>
    <v-card-title class="text-h4 mb-2 justify-center">Past Experiences</v-card-title>
    <v-timeline
        class="mx-7"
        align-top
        :dense="$vuetify.breakpoint.smAndDown"
    >
      <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          :color="item.color"
          :icon="item.icon"
          fill-dot
      >
        <v-card
            :color="item.color"
            dark
        >
          <v-card-title class="text-h6" v-text="item.title">
          </v-card-title>
          <v-card-text>
            <v-icon>mdi-calendar</v-icon>
            {{item.time}}
            <v-icon class="ml-5"> mdi-map-marker
            </v-icon>
            {{item.location}}
          </v-card-text>
          <v-card-text class="white text--primary">
            <p>{{item.content}}</p>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>

</template>

<script>
const items = [
  {
    color: 'primary',
    icon: 'mdi-book-open-variant',
    title: 'Undergraduate Student',
    location: 'UBC',
    time: '2017 - Present',
    content: 'I am currently an undergraduate student at UBC, major in Computer Science with GPA of 92.4%. ' +
        'I was awarded "Faculty of Science International Student Scholarship" in 2018, 2019, and 2020.'
  },
 {
    color: 'primary',
    icon: 'mdi-human-male-board',
    title: 'Teaching Assistant',
    location: 'UBC',
    time: 'Sep 2018 - Present',
    content: 'I was teaching assistant for multiple computer science courses, leading the discussions in tutorials and labs.' +
        'Never the less, I also led other TAs to develop the new assignment template that is used in a course project.'
  },  {
    color: 'primary',
    icon: 'mdi-server-network',
    title: 'Developer Intern',
    location: 'Demonware',
    time: 'Jan - Dec 2020',
    content: 'I was a developer intern at Demonware, where I developed an automated dependency updater' +
        ' and created Helm template to support Canary deployment.'
  },
]
export default {
  name: "Experiences",
  data () {
    return {
      items
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card flat>
    <v-card :height="avatarSize" flat>
      <v-img src="../assets/avatar.jpg" :width="avatarSize*2" :style="`top: -${avatarSize}px`" aspect-ratio="1" class="mx-auto rounded-circle"/>
    </v-card>
    <v-card-title class="justify-center">
      Gary (Zijia) Zhang
    </v-card-title>
    <v-card-subtitle class="text-center">
      A 4th Year Computer Science Student <strong>@</strong> UBC
    </v-card-subtitle>
    <useful-links/>
  </v-card>
</template>
<script>
import UsefulLinks from "@/components/UsefulLinks";

export default {
  components: {UsefulLinks},
  data () {
    return  {
      avatarSize: 100
    }
  }
}
</script>
<template>
  <v-card id="photos" class="my-7" rounded flat>
    <v-card-title class="text-h4 mb-2 justify-center">Photos</v-card-title>
    <v-card flat width="100%" tile>
      <v-row class="mx-5 d-flex justify-space-between" v-for="images in photoList" :key="images.key">
        <v-dialog
            v-model="dialog[image.src]"
            v-for="image in images.data.photo"
            :key="image.src"
            width="auto "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover
                v-slot="{ hover }"

            >
              <v-img :src="require(`../assets/photos_tiny/${image.src}`)" :class="`elevation-${hover ? 8: 0} my-1`"
                     :lazy-src="require(`../assets/photos_placeholder/${image.src}`)"
                     :width="`${(100 - images.data.photo.length * 0.5) * image.aspect/images.data.width}%`"
                     :aspect-ratio="image.aspect" style="margin-left: 0.25%; margin-right: 0.25%"
                     v-on="on"
                     v-bind="attrs"
              >      <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              </v-img>
            </v-hover>
          </template>

          <v-card>
            <v-lazy>
              <v-img
                  :src="require(`../assets/photos/${image.src}`)"
                  :lazy-src="require(`../assets/photos_tiny/${image.src}`)"
                  max-height="80vh"
                  :max-width="`${80 * image.aspect}vh`"
                  :aspect-ratio="image.aspect"
                  coverx
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>

              </v-img>
            </v-lazy>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="dialog[image.src] = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>

      </v-row>
    </v-card>
    <v-card-text class="text-center my-5">Still more to come... </v-card-text>
  </v-card>
</template>

<script>
const photos = [
  {
    src: "photo-01.jpg",
    aspect: 1024 / 439
  },
  {
    src: "photo-02.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-03.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-04.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-05.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-06.jpg",
    aspect: 1024 / 439
  },
  {
    src: "photo-07.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-08.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-09.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-10.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-11.jpg",
    aspect: 1024 / 767
  },
  {
    src: "photo-12.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-13.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-14.jpg",
    aspect: 683 / 1024
  },
  {
    src: "photo-15.jpg",
    aspect: 576 / 1024
  },
  {
    src: "photo-16.jpg",
    aspect: 1
  },
  {
    src: "photo-17.jpg",
    aspect: 576 / 1024
  },
  {
    src: "photo-18.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-19.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-20.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-21.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-22.jpg",
    aspect: 1024 / 576
  },
  {
    src: "photo-23.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-24.jpg",
    aspect: 675 / 1024
  },
  {
    src: "photo-25.jpg",
    aspect: 1024 / 768
  },
  {
    src: "photo-26.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-27.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-28.jpg",
    aspect: 1024 / 683
  },
  {
    src: "photo-29.jpg",
    aspect: 1024 / 767
  }
].sort((a, b) => b.aspect - a.aspect)

export default {
  name: "ImageGallary",
  data() {
    return {
      dialog: {},
      target_item_count: 0,
      target_row_count: 0
    }
  },
  methods: {
    windowSizeChanged() {
      let screen_width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) * 0.8;
      this.target_item_count = Math.max(screen_width / 300, 3);
      this.target_row_count = Math.floor(photos.length / this.target_item_count);
    },
    SeedRandom(state1, state2) {
      var mod1 = 4294967087
      var mul1 = 65539
      var mod2 = 4294965887
      var mul2 = 65537
      if (typeof state1 != "number") {
        state1 = +new Date()
      }
      if (typeof state2 != "number") {
        state2 = state1
      }
      state1 = state1 % (mod1 - 1) + 1
      state2 = state2 % (mod2 - 1) + 1

      function random(limit) {
        state1 = (state1 * mul1) % mod1
        state2 = (state2 * mul2) % mod2
        if (state1 < limit && state2 < limit && state1 < mod1 % limit && state2 < mod2 % limit) {
          return random(limit)
        }
        return (state1 + state2) % limit
      }

      return random
    },
    shuffle(array, seed) {
      var currentIndex = array.length, randomIndex;
      var generator = this.SeedRandom(seed, seed);
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = generator(currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    }
  },
  computed: {
    photoList: function () {
      let photo_rows = [];
      for (let i = 0; i < this.target_row_count; i++) {
        photo_rows.push({photo: [], width: 0, index: i});
      }
      for (let photo of photos) {
        let target_row = photo_rows[0];
        for (let row of photo_rows) {
          if (row.width < target_row.width) {
            target_row = row;
          }
        }
        target_row.photo.push(photo);
        target_row.width += photo.aspect;
      }
      return photo_rows.map((arr) => {
        return {
          key: arr.photo.map((image) => image.src).join(' '),
          data: {photo: this.shuffle(arr.photo, arr.photo.length + arr.index), width: arr.width}
        }
      });
    }
  },
  created() {
    window.addEventListener("resize", this.windowSizeChanged);
    this.windowSizeChanged();
  },
  destroyed() {
    window.removeEventListener("resize", this.windowSizeChanged);
  }
}
</script>

<style scoped>

</style>
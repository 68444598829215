<template>
  <v-footer
      padless
      class="justify-center my-2"
  >
    <v-card
        flat
        tile
        class="text-center"
        width="100%"
    >
      <v-card-text class="pb-1">
        <v-btn
            class="mx-4"
            icon
            link href="https://github.com/ZijiaZhang"
        >
          <v-icon size="50px">
            mdi-github
          </v-icon>
        </v-btn>
        <v-btn
            class="mx-4"
            icon
            link href="https://www.linkedin.com/in/zijia-zhang-4b4317178/"
        >
          <v-icon size="50px">
            mdi-linkedin
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-subtitle class="justify-center">Zijia.Zhang99@gmail.com</v-card-subtitle>

      <v-divider></v-divider>
      <v-card-text>
        {{ new Date().getFullYear() }} — <strong>Gary Zhang</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: 'padlessFooter'
}
</script>
<template>
  <v-app>
    <v-main>
      <TopBanner>
      </TopBanner>
      <v-card flat width="100%">
        <v-card flat width="80%" class="mx-auto">
          <Introduction/>
          <v-card flat class="mt-7">
            <personal-summary/>
            <experiences/>
            <projects/>
            <ImageGallary></ImageGallary>
          </v-card>
        </v-card>
      </v-card>
      <speed-dial/>
    </v-main>
    <padlessFooter/>
  </v-app>
</template>

<script>
import Introduction from './components/Introduction.vue';
import TopBanner from "@/components/TopBanner";
import PersonalSummary from "@/components/PersonalSummary";
import Projects from "@/components/Projects";
import ImageGallary from "./components/ImageGallary";
import Experiences from "@/components/Experiences";
import PadlessFooter from "@/PadlessFooter";
import SpeedDial from "@/components/SpeedDial";

export default {
  name: 'App',

  components: {
    SpeedDial,
    PadlessFooter,
    Experiences,
    ImageGallary,
    Projects,
    PersonalSummary,
    TopBanner,
    Introduction
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.v-speed-dial__list{
  flex-direction: column !important;
}
</style>

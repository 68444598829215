<template>
  <v-speed-dial
      v-model="fab"
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
  >
    <template v-slot:activator>
      <v-btn
          v-model="fab"
          color="blue darken-2"
          dark
          fab
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-menu
        </v-icon>
      </v-btn>
    </template>
    <v-btn
        text
        dark
        small
        color="primary"
        href="#about"
    >
      About Me
    </v-btn>
    <v-btn
        text
        dark
        small
        color="primary"
        href="#experiences"
    >
      Experience
    </v-btn>    <v-btn
      text
      dark
      small
      color="primary"
      href="#projects"
  >
    Projects
  </v-btn>
    <v-btn
        text
        dark
        small
        color="primary"
        href="#photos"
    >
      Photos
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  name: "SpeedDial",
  data () {
    return {
      fab: null,
    }
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: fixed;
}

.v-speed-dial--right {
  right: 30px;
}

.v-speed-dial--bottom {
  bottom: 30px;
}

.v-btn--floating {
  position: relative;
}
</style>
<template>
<v-card flat class="mt-2">
  <v-row class="justify-center">
    <v-btn class="mx-2" color="primary" link href="https://github.com/ZijiaZhang">Github</v-btn>
    <v-btn class="mx-2" color="primary" link href="https://www.linkedin.com/in/zijia-zhang-4b4317178/">LinkedIn</v-btn>
  </v-row>
</v-card>
</template>

<script>
export default {
  name: "UsefulLinks"
}
</script>

<style scoped>

</style>
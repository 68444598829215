<template>
  <div class="overflow-visible">
    <v-img src="../assets/banner.jpg" height="300">

    </v-img>
  </div>
</template>

<script>
export default {
  name: "TopBanner"
}
</script>

<style scoped>

</style>
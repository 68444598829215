<template>
  <v-hover
      v-slot="{ hover }"
      disabled
  >
    <v-card id="about" rounded :elevation="hover ? 5: 0" outlined>
      <v-card-title class="text-h4 mb-2 justify-center">About Me</v-card-title>
      <v-card-text>
        I am a Bachelor student major in Computer Science in University British Columbia since 2017.
        A side from studying, I have worked as a Teaching assistant for many different courses and also learnt
        programming languages, including Python, C++ and JavaScript etc.
        In Summer of 2019, I also participated in AISpace2, a research on Algorithm Visualizations.
        I went for co-op in 2020, and worked as software developer in Demonware for a year.
        During this time I further developed my Python and networking skills.
        Currently I am back at school and planning to graduate on April 2022.
      </v-card-text>
      <v-card-text class="d-flex">
        <v-spacer/>
        <v-btn color="primary" href="Gary_CV.pdf">Download Resume</v-btn>
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>
export default {
  name: 'personal-summary'
}
</script>
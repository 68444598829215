<template>
  <v-hover
      v-slot="{ hover }"
  >
    <v-card @click="openLink(project.link)" rounded min-width="30%"
            max-width="80%" class="mb-5 mx-4 d-flex flex-column" width="350" :elevation="hover? 5: 0" outlined>
      <v-window v-if="project.image" v-model="activeImg">
        <v-window-item v-for="image in project.image" :key="image.src">
          <v-img v-if="image.type === 'img'" :src="require(`../assets/projects/${image.src}`)" aspect-ratio="2"></v-img>
          <div v-else-if="image.type === 'vid'" style="aspect-ratio: 2; overflow: hidden">
           <video  :src="require(`../assets/projects/${image.src}`)" style="object-fit: contain; width: 100%; height: 100%" autoplay muted playsinline loop></video>
          </div>
        </v-window-item>
      </v-window>
      <v-card-actions  class="justify-space-between justify-center mx-auto">
        <v-item-group
            v-model="activeImg"
            class="text-center"
            v-visible="project.image.length > 1"
        >
          <v-item
              v-for="n in project.image.length"
              :key="`btn-${n}`"
              v-slot="{ active }"
          >
            <v-btn
                :input-value="active"
                icon
                small
                @click="update(n - 1)"
                v-on:click.stop
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-card-actions>
      <v-card-title v-text="project.name"></v-card-title>
      <v-card-subtitle
          v-text="project.description.length > 120? (project.description.slice(0,120) + '...') : project.description"></v-card-subtitle>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-chip class="mx-1" v-for="category in project.category" v-text="category" :key="category"></v-chip>
        <v-btn class="float-right" text color="primary" @click="openLink">Learn More</v-btn>
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>
export default {
  name: 'ProjectItem',
  props: {
    project: null,
    autoSlide: null
  },
  created() {
    if (this.autoSlide) {
      this.nextPlay = setInterval( this.next, 5000);
    }
  },
  data() {
    return {
      activeImg: 0,
      nextPlay: null
    }
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank')
    },
    next() {
      this.activeImg = this.activeImg + 1 === this. project.image.length
          ? 0
          : this.activeImg + 1;
    },
    update(n) {
      this.activeImg = n;
      if (this.nextPlay) {
        clearInterval(this.nextPlay);
        this.nextPlay = null;
        setTimeout(() => (this.nextPlay = setInterval( this.next, 5000)), 5000)
      }
    }
  }
}
</script>

<template>
  <v-card id="projects" class="mt-7" rounded flat>
    <v-card-title class="text-h4 mb-2 justify-center">Projects</v-card-title>
    <v-card class="d-flex flex-wrap align-content-center align-stretch justify-space-around" flat
            tile
    >
      <ProjectItem v-for="project in projects" :key="project.name" :project="project" :auto-slide="true"
      />
    </v-card>
  </v-card>
</template>

<script>
import ProjectItem from "./ProjectItem";

export default {
  name: "Projects",
  components: {ProjectItem},
  data() {
    return {
      projects: [
        {
          name: "BrewOL",
          link: "https://brewol.app/",
          description: "Brew is a web application that allows user to configure their Mac environment and install apps with one command.",
          status: "Personal",
          image: [{type: "img", src: "BrewOL.jpg"}, {type: "img", src: "BrewOL2.jpg"}],
          category: ['Node.js', 'Vue.js']
        },
        {
          name: "UBC Tantan",
          link: "https://github.com/ZijiaZhang/CPSC436_Project",
          description: "UBC TANTAN is a full-stack social networking web application, which allows new students to UBC to edit their profiles, share posts, add new friends, and chat with them. It also supports group chats, backend monitoring, and friend recommendations.",
          status: "Academic",
          image: [{type: "img", src:"UBCTanTan.jpg"}],
          category: ['Node.js', 'React']
        },
        {
          name: "Van Exposure",
          link: "https://github.com/ZijiaZhang/2020VanHacks",
          description: "A website provide visualization of possible COVID exposure based on BCCDC data and user reports.",
          status: "Hackathon",
          image: [{type: "img", src: "VanExposure.jpg"}],
          category: ['Node.js', 'React']
        },
        {
          name: "AMZ Park",
          link: "https://github.com/ZijiaZhang/AmzPark",
          description: "A sample management website for an amusement park. Functions like register ,login and make reservation are available on the customer side. Searching and manage status of the entertainment are available on the manager side.",
          status: "Academic",
          image: [{type: "img", src:"AMZPark.jpg"}],
          category: ['PHP', 'SQL', 'JS']
        },
        {
          name: "AISpace2",
          link: "https://aispace2.github.io/AISpace2/",
          description: "AISpace2 is a set of notebooks and an extension for Jupyter, a web application that combines code, text, and visualizations into a single, rich document. These notebooks teach and demonstrate AI concepts by providing detailed explanations alongside Python code implementations, and the accompanying extension brings these concepts to life by providing interactive visualizations driven directly by the code you see.",
          status: "Volunteer",
          image: [{type: "img", src:"AISpace2.jpg"}],
          category: ["Python", "TypeScript"]
        },
        {
          name: "ChatSim",
          link: "http://eml.ubc.ca/projects/language-chatsim/",
          description: "Language Chatsim is an ongoing collaboration between EML, UBC IT, a faculty member from the Department of Central, Eastern and Northern European Studies and a faculty member from the English Language Institute with a focus on developing a language learning application using virtual reality. The current version of Language Chatsim allows users to pick between English or German to practice speaking with an avatar in virtual reality. This avatar is connected to a chatbot built using Google's DialogFlow to allow for natural response to the users input. This process allows for students to naturally practice without the need to go out and find a practice partner.",
          status: "Volunteer",
          image: [{type: "img", src: "ChatSim.jpg"}],
          category: ['Unity', 'VR', 'Azure']
        }
      ]
    }
  }
}
</script>

<style scoped>
>>>
.v-card--link:focus:before{
  opacity: 0;
}
</style>